@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  font-family: "IBM Plex Sans Arabic", sans-serif;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  


  .swiper-button-prev:after, .swiper-button-next:after {
font-size: 17px !important;
color: white;
  }


  .swiper-pagination-bullet-active {
    background-color: white !important;
  }


  .placeholder_animation {
    background-image: radial-gradient(
      rgba(254, 254, 254, 0.925),
      rgba(215, 211, 223, 0.474)
    );
    /* background-image: radial-gradient(rgba(254, 254, 254, 0.925), rgba(210, 210, 210, 0.323)); */
    /* background-image: radial-gradient(
      
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.7) 60%,
      rgba(255, 255, 255, 0) 100%,
  
    ); */
    background-size: 200% 100%;
    animation: anmBg 0.8s linear infinite;
  }
  @keyframes anmBg {
    from{
      background-position: 0%;
    }
    to {
      background-position: -170% ;
    }
  }
 

  .categories-btns{
    transition: all 0.5s ease;
    height: 5rem;
    min-width: 7rem;
  }

  .categories-btns.active{
    height: 3rem;
  }

  .slide-anime > img {
    animation-name: slide-anime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0; /* Start with opacity 0 to fade in */
    transform: translateX(-20px);
}

@keyframes slide-anime {
    from {
        opacity: 0; /* Start with opacity 0 to fade in */
        transform: translateX(-20px); /* Start with a translateX */
    }
    to {
        opacity: 1; /* Fade in to full opacity */
        transform: translateY(0); /* Move to the original position */
    }
}

.slide-anime > img:nth-child(1) {
    animation-delay: 0s; /* Delay the animation for the first image by 0.2s */
}

.slide-anime > img:nth-child(2) {
    animation-delay: 0.4s; /* Delay the animation for the second image by 0.4s */
}

.slide-anime > img:nth-child(3) {
    animation-delay: 0.6s; /* Delay the animation for the third image by 0.6s */
}


.no-image{
  background-image: url('./images/noimage.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}